/* eslint-disable */
const Keys = {
  TAB: 'Tab',
  ENTER: 'Enter',
  ESCAPE: 27,
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
};

export default Keys;
